<template>
    <div
        v-if="offer.creditorName"
        class="ml-2"
    >
        <v-dialog v-model="showOfferDetails">
            <template #activator="{ on }">
                <v-icon
                    medium
                    :color="showOfferDetails ? 'primary' : ''"
                    v-on="on"
                >
                    info
                </v-icon>
            </template>

            <v-flex
                order-summary
                order-summary--column
                text-center
                justify-center
                class="pa-4"
            >
                <div class="close-action close-action--fluid">
                    <v-card-actions>
                        <button
                            class="button button--close button--close-rt-corner px-0"
                            @click="showOfferDetails = false"
                        >
                            <v-icon large>
                                close
                            </v-icon>
                        </button>
                    </v-card-actions>
                </div>
                <img
                    :src="offer.logo"
                    :alt="offer.creditorName"
                    class="creditor-logo"
                >

                <h2>Wybrałeś pożyczkę {{ offer.creditorName }} <br>na kwotę <strong>{{ offer.loanAmount / 100 | formatMoney }}</strong></h2>
            </v-flex>
        </v-dialog>
    </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import formatMoney from '~/mixins/filters/format-money'

export default {
    mixins: [
        formatMoney,
    ],
    data () {
        return {
            showOfferDetails: false,
        }
    },
    computed: {
        ...mapFields([
            'offer',
        ]),
    },
    watch: {
        showOfferDetails (newValue) {
            newValue
                ? this.$emit('dialog', newValue)
                : setTimeout(() => this.$emit('dialog'), 350)
        },
    },
}
</script>

<style lang="scss" scoped>
.order-summary {
    background: #fff;
    img {
        max-width: 80%;
    }
}
</style>
