<template>
    <v-navigation-drawer
        v-model="open"
        fixed
        bottom
        hide-overlay
        class="cart-mobile"
    >
        <div class="cart">
            <div class="cart__content">
                <div class="cart__header">
                    <h2 class="mb-0">
                        Koszyk
                    </h2>
                    <CartBadge
                        :number="products.length"
                        class="badge"
                    />
                </div>
                <div class="cart__products">
                    <div
                        v-for="product in products"
                        :key="product.id"
                        class="cart__products__item"
                    >
                        <div class="thumbnail">
                            <img
                                :src="product.photoUrl"
                                :alt="product.name"
                            >
                        </div>
                        <div class="content">
                            <div class="name">
                                {{ product.name }}
                            </div>
                            <div class="details">
                                <div class="quantity">
                                    {{ product.quantity }} szt
                                </div>
                                <div class="price">
                                    {{ product.price / 100 | formatMoney }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="cart__summary">
                <div
                    v-if="deliveryCost !== null"
                    class="cart__summary__item"
                >
                    <div class="label">
                        Dostawa
                    </div>
                    <div class="value">
                        {{ deliveryCost | formatMoney }}
                    </div>
                </div>
                <div class="cart__summary__item">
                    <div class="label">
                        Łącznie
                    </div>
                    <div class="value value--accent">
                        {{ totalAmount | formatMoney }}
                    </div>
                </div>
            </div>
        </div>
    </v-navigation-drawer>
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import CartBadge from '~/components/partials/cart/ecommerce/CartBadge'
import formatMoney from '~/mixins/filters/format-money'
import { EVENTS_COMFINO } from '~/utils/consts'

export default {
    components: {
        CartBadge,
    },
    mixins: [formatMoney],
    data () {
        return {
            open: false,
        }
    },
    computed: {
        ...mapFields(
            'ecommerce', [
                'cart.totalAmount',
                'cart.deliveryCost',
                'cart.products',
            ],
        ),
    },
    watch: {
        open (newValue) {
            this.$emit('change', newValue)
            if (newValue) {
                this.$analytics(EVENTS_COMFINO.CART_OPEN)
            } else {
                this.$analytics(EVENTS_COMFINO.CART_CLOSE)
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.cart-mobile {
    max-height: calc(100% - 68px);
    box-shadow: none;
}
.cart {
    overflow: auto;
    display: flex;
    flex-direction: column;
    height: 100%;
    background-attachment: fixed;
    background-size: cover;
    background-image: url('/backgrounds/background_ecommerce_mobile.jpg');
    &__content {
        flex-grow: 1;
        padding: 36px 15px;
    }
    &__header {
        display: flex;
        margin-bottom: 24px;
        .badge {
            margin-left: 6px;
        }
    }
    &__products {
        &__item {
            display: flex;
            &:not(:first-child) {
                margin-top: 16px;
            }
            .thumbnail {
                overflow: hidden;
                display: flex;
                justify-content: center;
                align-items: center;
                min-width: 72px;
                width: 72px;
                height: 72px;
                border-radius: 6px;
                background: #fff;
                img {
                    max-width: 100%;
                    max-height: 100%;
                }
            }
            .content {
                display: flex;
                flex-direction: column;
                margin-left: 16px;
                padding-bottom: 8px;
                .name {
                    font-size: 14px;
                    line-height: 1.7;
                }
                .details {
                    display: flex;
                    margin-top: auto;
                    font-size: 12px;
                    color: #aaa;
                    .quantity {
                        width: 40px;
                    }
                }
            }
        }
    }
    &__summary {
        padding: 16px 15px;
        background: #f3f3f3;
        &__item {
            display: flex;
            justify-content: space-between;
            font-size: 12px;
            line-height: 1.3;
            &:not(:first-child) {
                margin-top: 8px;
            }
            .value {
                &--accent {
                    font-weight: 500;
                    color: #03af36;
                }
            }
        }
    }
}
</style>
