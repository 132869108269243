export default ({ app }, inject) => {
    const saveDisplayedPath = async (type) => {
        type = type || 'DEFAULT'

        const route = app.router.currentRoute
        const payload = {
            token: route.query?.token || null,
            path: route.path,
            timestamp: Date.now().toString(),
            type: type,
        }

        await app.$axios.post('/statistics/paths', payload)
    }

    inject('statistics', {
        saveDisplayedPath,
    })
}
