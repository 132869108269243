<template>
    <div>
        <div class="hidden-md-and-down">
            <div
                v-show="installments"
                class="toolbar-order-summary"
            >
                <div class="toolbar-order-summary__labels">
                    <p>Kwota:</p>
                    <p>Liczba rat:</p>
                </div>
                <div class="toolbar-order-summary__data">
                    <p>{{ amount / 100 | formatMoney }}</p>
                    <p>{{ installments }}</p>
                </div>
            </div>
            <div
                v-show="!installments"
                class="toolbar-order-summary"
            >
                <p>
                    Kwota:<br>
                    <strong>{{ amount / 100 | formatMoney }}</strong>
                </p>
            </div>
        </div>
        <div class="hidden-lg-and-up">
            <div
                v-if="productBoxComponent.name === 'ProductBoxImaginaryInstallment'"
                class="toolbar-order-summary"
            >
                <p class="mb-0">
                    Rata:<br>
                    <strong class="dark-decor-font-color">{{ productBoxComponent.rata | formatMoney }}</strong>
                </p>
            </div>
            <div v-else-if="!$route.path.includes('gratulations')">
                <div class="toolbar-order-summary__labels text-right">
                    <p class="mb-0">
                        Kwota:<br><strong class="dark-decor-font-color">{{ getAmountValue / 100 | formatMoney }}</strong>
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import formatMoney from '~/mixins/filters/format-money'

export default {
    mixins: [formatMoney],
    computed: {
        ...mapFields([
            'loan.amount',
            'loan.installments',
            'offer',
            'whitelabel.source.productBoxComponent',
        ]),
        getAmountValue () {
            return (this.offer && this.offer.loanAmount) || this.amount
        },
    },
}
</script>

<style lang="scss" scoped>
@import "~/assets/versions/1/colors-palette.scss";

.toolbar-order-summary {
    display: flex;
    @include rem-fallback(font-size, 10);
    text-align: right;
    align-self: center;
    p {
        margin-bottom: 0;
    }
    &__data {
        font-weight: 700;
        text-align: left;
        @include rem-fallback(padding-left, 3);
    }
    &__labels {
        color: $default-font-color;
        font-size: 12px;
    }
}
</style>
