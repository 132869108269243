import Cookies from 'js-cookie'
import { COMFINO_SID_KEY } from '~/utils/consts'
import getStepFromPath from '~/utils/get-step-from-path'

export default async function getBackendDataLayer ({ $axios, route }) {
    const { token } = route.query
    if (!token) {
        return
    }

    const currentLayout = route.path.split('/')[1]
    if (currentLayout === 'ecommerce') {
        const sessionId = Cookies.get(COMFINO_SID_KEY)
        if (!sessionId) {
            return
        }
    } else {
        const currentStep = getStepFromPath(route.path) || ''
        if (['step-1', 'verify'].includes(currentStep)) {
            return
        }
    }

    try {
        const response = await $axios.get(`/orders/${token}/data-layer`, {
            validateStatus: function (status) {
                return status === 200 || status === 401
            },
        })

        const backendDataLayer = {}
        Object.entries(response.data).forEach(([key, value]) => {
            backendDataLayer[`backend_${key}`] = value
        })
        return backendDataLayer
    } catch (error) {
        console.error(error)
    }
}
