export default async function updateOrderData ($axios, store, queryToken) {
    const response = await $axios.get(`/orders/${queryToken}`)
    const orderData = response.data

    store.commit('setOrderData', {
        processType: orderData.processType,
        amount: orderData.loanAmount,
        installments: orderData.loanTerm,
        chosenOfferType: orderData.chosenOfferType,
        isCash: orderData.isCash,
    })
    store.commit('ecommerce/setStoreReturnUrl', orderData.continueUrl)
    store.commit('analytics/setComfinoChosenProduct', orderData.chosenOfferType)
}
