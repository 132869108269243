import { EVENTS } from '~/utils/consts'
import getBackendDataLayer from './utils/get-backend-data-layer'

export default async function (context) {
    const { store, $analytics } = context

    const backendDataLayer = await getBackendDataLayer(context)
    if (backendDataLayer) {
        store.commit('analytics/setBackendDataLayer', backendDataLayer)
    }
    $analytics(EVENTS.PAGE_LOADED)
}
