import { UAParser } from 'ua-parser-js'
export default ({ app }, inject) => {
    const createUAParser = () => {
        // exmaple Xiaomi User-Agent
        // 'Mozilla/5.0 (Linux; Android 13; 2201122G Build/TKQ1.220807.001; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/111.0.5563.116 Mobile Safari/537.36 [FB_IAB/FB4A;FBAV/410.0.0.26.115;]'
        return UAParser(window.navigator.userAgent)
    }

    const isDesktop = () => {
        const { device } = createUAParser()

        return device.type === undefined || !['wearable', 'mobile'].includes(device.type)
    }

    const isXiaomi = () => {
        const { device } = createUAParser()
        const { model, vendor } = device

        if (model && model.includes('Xiaomi')) {
            return true
        }

        if (vendor && vendor.includes('Xiaomi')) {
            return true
        }

        return false
    }

    inject('uaParser', {
        isDesktop,
        isXiaomi,
    })
}
