export const COMFINO_SID_KEY = 'Comfino-SID'
export const ORDER_TOKEN_KEY = 'Token'

export const EVENTS = {
    PAGE_LOADED: 'event_pageLoaded',
    COOKIE_INFO_CLICK: 'click_cookie-info',
    PRIVACY_POLICY_OPEN: 'click_open_privacy-policy',
    PRIVACY_POLICY_CLOSE: 'click_close_privacy-policy',
    TERMS_OF_USE_OPEN: 'click_open_terms-of-use',
    TERMS_OF_USE_CLOSE: 'click_close_terms-of-use',
    FAQ_OPEN: 'click_open_faq',
    FAQ_CLOSE: 'click_close_faq',
    AGREEMENTS_EXPAND: 'click_expand_agreements',
    AGREEMENTS_COLLAPSE: 'click_collapse_agreements',
    ADDITONAL_FIELDS_LINK_CLICK: 'click_additional-fields-link',
    SEND_AGAIN_CLICK: 'click_a_send-again',
    RECAPTCHA_VIEW: 'custom_view-recaptcha',
    RECAPTCHA_PASSED: 'custom_passed-recaptcha',
    CODE_INVALID: 'invalid-submit_code',
    CODE_ATTEMPTS_LIMIT_EXCEEDED: 'code-attempts-limit-exceeded',
    WAITING_PROPOSITION_TIME: 'custom_time-waiting-proposition',
    LONG_REQUEST_REDIRECT_TIME: 'custom_time-long-request-redirect',
}

export const EVENTS_CR = {
    START_ORDER_CLICK: 'click_button_start-order',
    MISTAKE_PHONE_NUMBER: 'click_a_mistake-phone-number',
    BUTTON_NEXT_STEP_CLICK: 'click_button_next-step',
    OFFER_GATEWAY: 'custom_offer-gateway',
}

export const EVENTS_COMFINO = {
    PAGE_PRODUCT_SELECTION_LOADED: 'custom_loaded-page-product-selection',
    PRODUCTS_OFFER: 'custom_offer-products',
    GATEWAY_OFFER: 'custom_offer-gateway',
    GATEWAY_OFFER_ALTERNATIVE: 'custom_offer-gateway-alternative',
    GATEWAY_OFFER_ALTERNATIVE_CHANGE: 'custom_change_offer-gateway-alternative',
    CART_OPEN: 'click_open_cart',
    CART_CLOSE: 'click_close_cart',
    BUTTON_CONFIRM_CLICK: 'click_button_confirm',
    BUTTON_NEXT_STEP_CLICK: 'click_button_next-step',
    LOGIN_SUBMIT: 'click_button_login-submit',
    LOGIN_INVALID_SUBMIT: 'invalid-submit_login',
    LOGIN_FORGOT_PASSWORD_CLICK: 'click_a_login-forgot-password',
    LOGIN_FORGOT_PASSWORD_SUBMIT: 'click_button_login-forgot-password',
    LOGIN_BACK_CLICK: 'click_a_login-back',
    AGREEMENTS_LIST_OPEN: 'click_open_agreements-list',
    AGREEMENTS_LIST_CLOSE: 'click_close_agreements-list',
    AGREEMENTS_HOW_WE_DO_OPEN: 'click_open_agreements-how-we-do',
    AGREEMENTS_HOW_WE_DO_CLOSE: 'click_close_agreements-how-we-do',
    POPUP_ERROR: 'custom_error-popup',
    PRODUCT_CHOOSE: 'custom_choose_offer-product',
    ID_TYPE_CHANGE: 'change_id-type',
    BUTTON_BACK_CLICK: 'click_button_back-step',
    REPRESENTATIVE_EXAMPLE_OPEN: 'click_open_representative-example',
    REPRESENTATIVE_EXAMPLE_CLOSE: 'click_close_representative-example',
    INCOME_SOURCES_ADD_CLICK: 'click_income-sources-add',
    INCOME_SOURCES_REMOVE_CLICK: 'click_income-sources-remove',
    ADDITONAL_FIELDS_LINK_CLICK: 'click_additional-fields-link',
    COOKIE_INFO_CLICK: 'click_cookie-info',
    CHOOSE_GATEWAY_OFFER: 'custom_choose_getaway-offer',
}
