<template>
    <v-dialog
        :value="value"
        max-width="1000px"
        @input="$emit('input', false)"
    >
        <v-btn
            icon
            class="dialog-close"
            @click="$emit('input', false)"
        >
            <v-icon>
                close
            </v-icon>
        </v-btn>
        <div class="dialog-content">
            <slot></slot>
        </div>
    </v-dialog>
</template>

<script>
export default {
    props: {
        value: {
            type: Boolean,
            default: false,
        },
    },
}
</script>

<style lang="scss">
.v-dialog {
    position: relative;
    display: flex;
    flex-direction: column;
    .dialog-close {
        position: absolute;
        z-index: 1;
        top: 8px;
        right: 8px;
    }
    .dialog-content {
        overflow: auto;
        max-height: 100%;
        padding: 24px 16px;
        background: #fff;
    }
    img { // for image on top of TOS
        max-width: 100%;
    }
}

@include media-breakpoint-up(lg, $vuetify-grid-breakpoints) {
    .v-dialog {
        .dialog-close {
            right: 16px;
        }
    }
}
</style>
