<template>
    <img
        :src="imageUrl"
        class="toolbar__logo"
        alt="logo ComperiaRaty"
        title="logo ComperiaRaty"
    />
</template>
<script>
export default {
    props: {
        imageUrl: {
            type: String,
            default: '/logo.svg',
        },
    },
}
</script>
