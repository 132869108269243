<template>
    <v-footer
        height="auto"
        class="footer px-0"
    >
        <v-container
            :py-6="$vuetify.breakpoint.smAndDown"
            :py-4="$vuetify.breakpoint.mdAndUp"
        >
            <v-layout
                :column="$vuetify.breakpoint.mdAndDown"
                justify-start
                :align-center="$vuetify.breakpoint.mdAndUp"
                :align-start="$vuetify.breakpoint.mdAndDown"
                wrap
            >
                <v-flex
                    footer__menu
                    xs12
                    md5
                    offset-lg1
                >
                    <div
                        class="footer__link"
                        :class="{'ml-0': $vuetify.breakpoint.mdAndDown}"
                        @click="showTermsOfService = true"
                    >
                        Regulamin
                    </div>
                    <div
                        class="footer__link"
                        @click="showPrivacyPolicy = true"
                    >
                        Polityka Prywatności
                    </div>
                    <div
                        class="footer__link"
                        @click="showFAQ = true"
                    >
                        FAQ
                    </div>
                </v-flex>
                <v-flex
                    footer__copyrights
                    xs12
                    md5
                    :pt-6="$vuetify.breakpoint.mdAndDown"
                >
                    Copyright {{ new Date().getFullYear() }} ComperiaRaty
                </v-flex>
                <button
                    class="footer__scroll-btn"
                    @click="scrollToTop"
                >
                    <img
                        width="32"
                        src="/combined-shape.svg"
                    >
                </button>
            </v-layout>
        </v-container>
        <TermsOfService />
        <PrivacyPolicy />
        <FAQ />
    </v-footer>
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import TermsOfService from '~/components/partials/terms-of-service/1/TermsOfService'
import PrivacyPolicy from '~/components/partials/privacy-policy/1/PrivacyPolicy'
import FAQ from '~/components/partials/faq/1/FAQ'

export default {
    components: {
        TermsOfService,
        PrivacyPolicy,
        FAQ,
    },
    computed: {
        ...mapFields([
            'showTermsOfService',
            'showPrivacyPolicy',
            'showFAQ',
        ]),
    },
    methods: {
        scrollToTop () {
            document.body.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            })
        },
    },
}
</script>

<style lang="scss">
@import '~/assets/versions/1/colors-palette.scss';

.footer {
    background: $footer-background;
    position: relative;
    &__menu {
        text-align: left;
    }
    &__link {
        display: inline-block;
        font-weight: 500;
        transition: color .2s;
        @include rem-fallback(font-size, 14);
        @include rem-fallback(margin, 0, 5);
        cursor: pointer;
        &:hover {
            color: $primary;
        }
    }
    &__copyrights {
        @include rem-fallback(font-size, 14);
        text-align: center;
    }
    &-gradient {
        background: linear-gradient(92deg, #6ce8fd, #0d015b) !important;
        color: #fff !important;
        .footer__copyrights {
            background: transparent;
            text-align: right;
        }
    }
    &__scroll-btn {
        position: absolute;
        right: 20px;
        top: 22px;
    }
}
.close-action {
    position: sticky;
    top: 48px;
    &--fluid {
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
    }
}
@include media-breakpoint-up(sm, $vuetify-grid-breakpoints) {
    .footer {
        &__link {
            @include rem-fallback(font-size, 13);
        }
        &__copyrights {
            @include rem-fallback(font-size, 13);
            text-align: right;
        }
        &-gradient {
            .footer__copyrights {
                background: transparent;
            }
        }
    }
}
@include media-breakpoint-up(md, $vuetify-grid-breakpoints) {
    .footer {
        &__menu {
            text-align: left;
        }
        &__scroll-btn {
            display: none;
        }
    }
}
@include media-breakpoint-up(lg, $vuetify-grid-breakpoints) {
    .footer {
        &__link {
            @include rem-fallback(font-size, 14);
            @include rem-fallback(margin, 0, 10);
        }
        &__copyrights {
            @include rem-fallback(font-size, 14);
        }
    }
}
</style>
