export const UTM_KEYS = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content']

export function hasRouteUTM (route) {
    return UTM_KEYS.some(utmKey => Object.keys(route.query).includes(utmKey))
}

export function prepareRouteWithUTM (route, from) {
    const routeWithUTM = { ...route }
    UTM_KEYS.forEach(utmKey => {
        routeWithUTM.query[utmKey] = from.query[utmKey]
    })
    return routeWithUTM
}
