<template>
    <v-app class="olx">
        <div class="height-wrapper">
            <header class="header">
                <h1 class="desktop-only">
                    Kupuj wygodnie, płacisz kiedy zdecydujesz
                </h1>
                <img
                    src="/olx/logo.png"
                    alt="Logo"
                    class="logo mobile-only"
                />
            </header>
            <nuxt />
            <AppPopup />
        </div>
    </v-app>
</template>
<script>
import Popup from '~/components/popups/Popup'

export default {
    components: {
        AppPopup: Popup,
    },
    head () {
        return {
            title: 'Wniosek COMPERIARATY',
        }
    },
}
</script>
<style lang="scss">
@import "~/assets/widget/styles.scss";
.olx {
    color: #002f34;
    .header {
        color: #3b76ff;
        h1 {
            font-weight: 700;
        }
    }
    .content a, .back a  {
        color: #002f34;
    }
    .change-number, .verification__input  {
        .v-input__slot {
            color: #002f34;
            border-color: #002f34;
            border-radius: 4px !important;
            input {
                color: #002f34 !important;
            }
        }
    }
    @media (max-width: 599px) {
        .header img {
            height: 30px;
        }
        .content {
            h1 {
                color: #3b76ff;
                font-weight: 700;
            }
        }
    }
}
</style>
