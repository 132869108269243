import { mapFields } from 'vuex-map-fields'
import { EVENTS } from '~/utils/consts'

export default {
    computed: {
        ...mapFields([
            'termsOfService',
            'showTermsOfService',
        ]),
    },
    watch: {
        showTermsOfService (value) {
            if (value) {
                this.$analytics(EVENTS.TERMS_OF_USE_OPEN)
            } else {
                this.$analytics(EVENTS.TERMS_OF_USE_CLOSE)
            }
        },
    },
}
