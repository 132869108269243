import Cookies from 'js-cookie'
import { COMFINO_SID_KEY } from '~/utils/consts'

export default function ({ $axios, store, app }, inject) {
    $axios.defaults.baseURL = process.env.API_CUSTOMER_ORIGIN
    $axios.onRequest(config => {
        config.headers.common[COMFINO_SID_KEY] = Cookies.get(COMFINO_SID_KEY)
        const { unlockToken } = store.state
        if (unlockToken) {
            config.headers.common['Unlock-Token'] = unlockToken
        }
    })

    $axios.interceptors.request.use((request) => {
        if (store.state.isWindowActive === false) {
            return
        }
        return request
    })

    $axios.interceptors.response.use((response) => response, (error) => {
        if (store.state.isWindowActive === false) {
            return
        }

        if (process.env.NODE_ENV === 'development') {
            console.error(error)
        }

        const code = parseInt(error.response && error.response.status)
        if (error.request) {
            error.message = error.request.responseURL + ' returned ' + code
        }

        if (code === 401) {
            handleAuthorizationError(error, app)
        } else if (code === 403) {
            location.reload()
        }

        return Promise.reject(error)
    })
}

// eslint-disable-next-line node/handle-callback-err
function handleAuthorizationError (error, app) {
    const currentRoute = app.router.currentRoute
    const token = currentRoute.query.token
    if (!token) {
        return
    }

    // refresh because loader crashes in some edge redirect cases
    if (currentRoute.name.includes('verify')) {
        location.reload()
        return
    }

    if (currentRoute.name.includes('ecommerce')) {
        app.router.push(`/ecommerce/verify?token=${token}`)
        return
    }

    app.router.push(`/1/verify?token=${token}`)
}
