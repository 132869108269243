<template>
    <Loader
        v-if="loading"
        full-screen
    />
</template>

<script>
import Loader from '~/components/partials/Loader'

export default {
    components: {
        Loader,
    },
    data: () => ({
        loading: true,
    }),
    watch: {
        $route (to) {
            this.$nextTick(() => {
                this.start()
                setTimeout(() => this.finish(), 100)
            })
        },
    },
    mounted () {
        this.finish()
    },
    methods: {
        start () {
            this.loading = true
        },
        finish () {
            this.loading = false
        },
    },
}
</script>
