<template>
    <Dialog v-model="showPrivacyPolicy">
        <div v-dompurify-html="privacyPolicy"></div>
    </Dialog>
</template>

<script>
import Dialog from '~/components/partials/dialog/1/Dialog'
import privacyPolicy from '../privacy-policy'

export default {
    components: {
        Dialog,
    },
    mixins: [privacyPolicy],
}
</script>
