<template>
    <div
        class="loader"
        view
        :class="{
            'app-loaded': viewLoaded,
            'loader--fullscreen': fullScreen
        }"
    >
        <div class="c-loader">
            <img
                v-if="processType === 'ECOMMERCE'"
                src="/favicon-comfino.svg"
                class="comfino-loader-logo"
                :class="{
                    'comfino-loader-logo--linear': linear,
                }"
            />
            <div
                v-if="processType !== 'ECOMMERCE'"
                class="c-loader__icon"
            >
                <div class="c-loader__el"></div>
                <div class="c-loader__el"></div>
                <div class="c-loader__el"></div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields'

export default {
    props: {
        viewLoaded: {
            type: Boolean,
            default: false,
        },
        fullScreen: {
            type: Boolean,
            default: true,
        },
        linear: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapFields([
            'processType',
        ]),
    },
}
</script>

<style lang="scss" scoped>
.loader {
    justify-content: center;
    align-items: center;
    left: 0;
    top: 0;
    transition: all .4s ease-in-out;

    &--fullscreen {
        position: fixed;
        display: flex;
        width: 100%;
        height: 100%;
        z-index: 1000;
        background: #ffffff;
    }

    &.app-loaded {
        opacity: 0;
        pointer-events: none;
    }
}

@keyframes cloader-1 {
    0% {
        opacity: 0;
    }
    30%, 100%{
        opacity: 1;
    }
}

@keyframes cloader-2 {
    30% {
        opacity: 0;
    }
    60%, 100%{
        opacity: 1;
    }
}

@keyframes cloader-3 {
    60% {
        opacity: 0;
    }
    90%, 100%{
        opacity: 1;
    }
}
.c-loader {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 130px;

    &__icon {
        width: 80px;
        height: 80px;
        position: relative;
    }

    &__el {
        width: 50%;
        height: 50%;
        position: absolute;
        right: 0;
        top: 0;
        background: #4394cb;
        border-radius: 10px 10px 0 0;
        transform: rotate(45deg);
        opacity: 0;

        &:nth-of-type(1) {
            animation: cloader-1 1s ease 0s infinite both alternate;
        }

        &:nth-of-type(2) {
            background: #0164a8;
            right: auto;
            left: 12px;
            top: 28px;
            transform: rotate(-135deg);
            border-radius: 0 10px 0 0;
            animation: cloader-2 1s ease 0s infinite both alternate;
        }

        &:nth-of-type(3) {
            background: #413f7d;
            bottom: -16px;
            top: auto;
            transform: rotate(135deg);
            animation: cloader-3 1s ease 0s infinite both alternate;
        }
    }

    @keyframes rotating {
        from {
            -ms-transform: rotate(0deg);
            -moz-transform: rotate(0deg);
            -webkit-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        to {
            -ms-transform: rotate(360deg);
            -moz-transform: rotate(360deg);
            -webkit-transform: rotate(360deg);
            -o-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }

    .comfino-loader-logo {
        max-width: 100%;
        animation: rotating 1.2s cubic-bezier(0.6, 0.12, 0.4, 0.83);
        animation-iteration-count: infinite;

      &--linear {
        animation: rotating 1.2s linear !important;
        animation-iteration-count: infinite !important;
      }
    }
}
</style>
