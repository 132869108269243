export default {
    data () {
        return {
            tabs: [
                {
                    label: 'Weryfikacja numeru',
                    steps: ['verify'],
                },
                {
                    label: 'Dane osobowe',
                    steps: ['step-1', 'step-2', 'step-2-1'],
                },
                {
                    label: 'Adres',
                    steps: ['step-3'],
                },
                {
                    label: 'Propozycje finansowania',
                    steps: [
                        'product-selection',
                        'offers-ecommerce',
                        'offers',
                        'long-request-redirect',
                    ],
                },
                {
                    label: 'Dane finansowe',
                    steps: ['income-sources'],
                },
                {
                    label: 'Weryfikacja',
                    steps: [
                        'additional-fields',
                        'waiting',
                        'update-order',
                        'waiting/update',
                    ],
                },
                {
                    label: 'Umowa',
                    steps: [],
                },
                {
                    label: 'Realizacja zamówienia',
                    steps: ['thank-you', 'cancelled'],
                },
            ],
        }
    },
    computed: {
        activeTabIndex () {
            let activeIndex = -1
            this.tabs.forEach((tab, index) => {
                tab.steps.forEach(step => {
                    if (this.$route.path.includes(step)) {
                        activeIndex = index
                    }
                })
            })
            return activeIndex
        },
        tabsVisible () {
            return this.$route.path !== '/ecommerce/unknown-order-show-fail-copy'
        },
    },
}
