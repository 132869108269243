<template>
    <v-app class="layout-1">
        <v-toolbar
            class="hidden-lg-and-up elevation-1 flex-grow-0 mb-1"
            :color="themeColors['app-background']"
            :height="'62px'"
        >
            <v-toolbar-title>
                <img
                    src="/logo.svg"
                    class="toolbar__logo"
                    alt="logo ComperiaRaty"
                    title="logo ComperiaRaty"
                />
            </v-toolbar-title>

            <v-spacer></v-spacer>
        </v-toolbar>

        <AppToolbar
            class="hidden-md-and-down"
            style="margin-bottom: 3px;"
        />

        <v-main>
            <v-container
                px-0
                py-0
                fluid
                class="verification d-flex justify-center"
                style="height: 100%;"
                :style="{ 'background-color': $vuetify.breakpoint.mdAndDown ? 'white' : themeColors['app-background'] }"
            >
                <nuxt />
            </v-container>
        </v-main>
        <AppFooter />
        <AppPopup />
        <AppCookieInfo />
    </v-app>
</template>

<script>
import Toolbar from '~/components/partials/toolbar/1/Toolbar'
import Footer from '~/components/partials/footer/1/Footer'
import Popup from '~/components/popups/Popup'
import CookieInfo from '~/components/partials/cookie-info/1/CookieInfo'
import themeColors from '~/assets/versions/1/colors-palette.scss'

export default {
    components: {
        AppToolbar: Toolbar,
        AppFooter: Footer,
        AppPopup: Popup,
        AppCookieInfo: CookieInfo,
    },
    data () {
        return {
            themeColors,
        }
    },
    head () {
        return {
            title: 'Wniosek COMPERIARATY',
        }
    },
}
</script>

<style lang="scss">
.layout-1 {
    @import '~/assets/versions/1/colors-palette.scss';
    .verification {
        font-size: 16px;
        a {
            text-decoration: none;
        }
        &__close-link {
            font-size: 12px;
            color: $light-font-color;
            text-decoration: none;
            margin-top: 20px;
        }
        &__input {
            font-weight: 500;
            font-size: 20px;
            max-width: 62px;
            max-height: 56px;
            border-radius: 4px;
            transition: all 0.15s ease-in-out;
            input {
                text-align: center;
            }
            &--active {
                box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.2), 0 5px 22px 4px rgba(0, 0, 0, 0.12);
            }
        }
        &__actions {
            p {
                font-size: 12px;
                color: $dark-font-color;
                margin-bottom: 0;
            }
            a {
                font-size: 16px;
                color: $dark-font-color;
            }
        }
    }
    .sms-verify {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        &__container {
            background: white;
            border-radius: 2%;
        }
        &__header {
            color: $primary;
            font-size: 29px;
            p {
                font-size: 14px;
                color: $default-font-color;
                margin-bottom: 0;
            }
        }
    }
    @include media-breakpoint-up(xl, $vuetify-grid-breakpoints) {
        .sms-verify {
            &__header {
            font-size: 34px;
            }
            &__container {
            padding: 40px 100px;
            }
        }
    }
    @include media-breakpoint-down(lg, $vuetify-grid-breakpoints) {
        .sms-verify {
            &__container {
            padding: 15px 25px;
            }
        }
    }
}
</style>
