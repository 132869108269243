<template>
    <div>
        <v-dialog
            :value="isMounted && visible"
            width="500"
            @click:outside="visible = false"
        >
            <v-card class="pt-4 pb-2 px-6">
                <v-icon
                    color="error"
                    size="33"
                >
                    warning
                </v-icon>
                <div class="pt-6 pb-4 font-weight-medium title error--text">
                    {{ title }}
                </div>
                <div>{{ text }}</div>
                <v-card-actions class="mt-1">
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        text
                        @click="visible = false"
                    >
                        Zamknij
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields'

export default {
    data () {
        return {
            isMounted: false,
        }
    },
    computed: {
        ...mapFields([
            'popup.visible',
            'popup.type',
            'popup.title',
            'popup.text',
        ]),
    },
    mounted () {
        this.isMounted = true
    },
}
</script>
