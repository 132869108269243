<template>
    <div
        v-if="show"
        class="cookie-info elevation-13 cookie-info--bottom"
    >
        <v-container pa-0>
            <div
                class="cookie-info__wrapper"
            >
                <div
                    class="text-center"
                    :class="{'mb-2 ': $vuetify.breakpoint.smAndDown}"
                >
                    Nasza strona używa plików Cookies. Dowiedz się więcej o celu ich używania - zapoznaj się z
                    <span
                        class="cookie-info__link"
                        @click="showPrivacyPolicy = true"
                    >
                        Polityką prywatności.
                    </span>
                </div>
                <div class="cookie-info__actions">
                    <v-btn
                        id="cookie-accept-btn"
                        small
                        class="button button--gradient px-8 my-0"
                        @click.native="accept"
                    >
                        Akceptuję
                    </v-btn>
                </div>
            </div>
        </v-container>
    </div>
</template>
<script>
import { mapFields } from 'vuex-map-fields'
import { EVENTS } from '~/utils/consts'

export default {
    data () {
        return {
            show: false,
        }
    },
    computed: {
        ...mapFields([
            'showPrivacyPolicy',
        ]),
    },
    mounted () {
        if (localStorage && localStorage.getItem('cookieAccepted')) {
            this.show = false
            return
        }
        this.show = true
    },
    methods: {
        accept () {
            this.$analytics(EVENTS.COOKIE_INFO_CLICK)
            this.show = false
            if (localStorage) {
                localStorage.setItem('cookieAccepted', new Date().getTime())
            }
        },
    },
}
</script>

<style lang="scss" scoped>
@import '~/assets/versions/1/colors-palette.scss';

.cookie-info {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 12px 0;
    background: #fff;
    z-index: 10000;
    transition: opacity .4s ease-in 0s;
    font-size: 13px;

    &--bottom {
        top: auto;
        bottom: 0;
    }

    &__wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding: 0 15px;
    }

    &__link {
        color: $primary;
    }
}

@include media-breakpoint-up(md, $vuetify-grid-breakpoints) {
    .cookie-info {
        &__wrapper {
            flex-direction: row;
        }
    }
}
</style>
