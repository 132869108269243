export const getStatusCode = (error) => {
    return error.response ? error.response.status : null
}

export const handleError = ($sentry, $router, $route, $store, $popup, error, message) => {
    if (getStatusCode(error) === 401) {
        $store.commit('setAllowedSteps', ['verify'])
        $router.push(`/1/verify?token=${$route.query.token}`)
        return false
    }

    errorReport($sentry, $popup, error, message)
    return false
}

export const handlerErrorEcommerce = async ($sentry, $router, $axios, $route, $popup, error, message) => {
    if (getStatusCode(error) === 401) {
        // handlerErrorEcommerce is called in flow-step-ecommerce.js
        const { token } = $route.query
        const stepResponse = await $axios.get(`/orders/${token}/step`)
        const flowStep = stepResponse.data
        $router.push(`/${flowStep.layout}/${flowStep.step}?token=${token}`)

        return false
    }

    errorReport($sentry, $popup, error, message)
    return false
}

function errorReport ($sentry, $popup, error, message) {
    if (!error.sentryId) {
        error.sentryId = $sentry.captureException(error)
    }
    $popup.error(message, error.sentryId)
}
