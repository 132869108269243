<template>
    <v-flex>
        <Slider
            v-if="amount && processType !== 'ECOMMERCE'"
            id="loan-amount-input"
            v-model="amountInZloty"
            label="Chcę pożyczyć"
            unit="zł"
            :controls="controls"
            :controls-always="controlsAlways"
            :min="minLoanValue"
            :max="limitValues.loanAmount.max / 100"
            :step="limitValues.loanAmount.step / 100"
            @input="handleAmountInput"
        />

        <Slider
            v-if="installments"
            id="loan-term-input"
            v-model="installments"
            label="Liczba rat"
            unit="mies."
            :controls="controls"
            :controls-always="controlsAlways"
            :min="limitValues.loanTerm.min"
            :max="limitValues.loanTerm.max"
            @input="handleTermInput"
        />
    </v-flex>
</template>

<script>
import { mapFields } from 'vuex-map-fields'

import Slider from '~/components/ui/Slider'

export default {
    components: {
        Slider,
    },
    props: {
        controls: {
            type: Boolean,
            default: false,
        },
        controlsAlways: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapFields([
            'processType',
            'loan.amount',
            'loan.installments',
        ]),
        ...mapFields('configuration', [
            'limitValues',
        ]),
        amountInZloty: {
            get () {
                return this.amount / 100
            },
            set (newValue) {
                this.amount = newValue * 100
            },
        },
        minLoanValue () {
            return Math.max(this.limitValues.loanAmount.min, 100000) / 100
        },
    },
    created () {
        if (!this.amount && !this.installments && !this.$route.query.token) {
            // index vs offers page
            this.amount = this.limitValues.loanAmount.default
            this.installments = this.limitValues.loanTerm.default
        }
    },
    methods: {
        handleAmountInput (value) {
            this.$analytics('input_loan-amount', { value })
        },
        handleTermInput (value) {
            this.$analytics('loan-amount-term', { value })
        },
    },
}
</script>
