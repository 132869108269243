// {
//     layout: {
//         partner: {
//             step: {
//                 key: 'copy'
//             }
//         }
//     }
// }

export default {
    ecommerce: {
        default: {
            'step-1': {
                h3: 'Szukamy najlepszej oferty na sfinalizowanie Twoich zakupów.',
            },
            'product-selection': {
                waiting: 'Już za chwilę dopasujemy specjalnie dla Ciebie najlepsze sposoby płatności, dzięki którym zapłacisz za swoje zakupy.',
                h2: 'Zapłać później, jak lubisz.',
                h3: 'nie odkładaj marzeń na później. Wybierz najkorzystniejszy dla siebie sposób płatności i zrealizuj zakupy już dziś.',
            },
            offers: {
                waiting: 'Już za chwilę dopasujemy specjalnie dla Ciebie najlepsze sposoby płatności, dzięki którym zapłacisz za swoje zakupy.',
            },
            'offers-ecommerce': {
                h2: 'Zapłać później, jak lubisz.',
            },
            'waiting/proposition': {
                h2: 'Sprawdzamy dla Ciebie ofertę.<br />Za chwilę zostaniesz przekierowany na wniosek kredytowy.',
            },
            'waiting/update': {
                h2: 'Trwa aktualizacja kwoty na wniosku o sfinansowanie Twoich zakupów.<br />Za chwilę zostaniesz przekierowany na stronę InBanku, aby dokończyć proces.',
            },
            'thank-you': {
                text: 'a Ty niebawem będziesz mógł cieszyć się swoimi zakupami.',
            },
            'return-before-end': {
                title: 'Już wkrótce będziesz mógł cieszyć się swoimi zakupami.',
                important: 'Wykonaj przelew od razu, aby szybciej uzyskać towar.',
            },
            'update-order': {
                text: 'Zmieniła się kwota Twoich zakupów.',
            },
        },
        'Kliniki.pl': {
            'step-1': {
                h3: 'Szukamy najlepszej oferty na sfinalizowanie Twojej potrzeby.',
            },
            'product-selection': {
                waiting: 'Już za chwilę dopasujemy specjalnie dla Ciebie najlepsze sposoby płatności, dzięki którym sfinansujesz wybraną usługę medyczną.',
                h2: 'Korzystaj z usług medycznych już teraz, zapłać później.',
                h3: 'wybierz najwygodniejszą dla Ciebie metodę płatności za usługę medyczną.',
            },
            offers: {
                waiting: 'Już za chwilę dopasujemy specjalnie dla Ciebie najlepsze sposoby płatności, dzięki którym sfinansujesz wybraną usługę medyczną.',
            },
            'offers-ecommerce': {
                h2: 'Korzystaj z usług medycznych już teraz, zapłać później',
            },
            'waiting/proposition': {
                h2: 'Sprawdzamy dla Ciebie ofertę.<br />Za chwilę zostaniesz przekierowany na wniosek kredytowy.',
            },
            'waiting/update': {
                h2: 'Trwa aktualizacja kwoty na wniosku o sfinansowanie wybranej usługi medycznej.<br />Za chwilę zostaniesz przekierowany na stronę InBanku, aby dokończyć proces.',
            },
            'thank-you': {
                text: 'a Ty już niedługo będziesz mógł wykonać wybrany zabieg.',
            },
            'return-before-end': {
                title: 'Już wkrótce będziesz mógł cieszyć się swoimi zakupami.',
                important: 'Wykonaj przelew od razu, aby szybciej uzyskać towar.',
            },
            'update-order': {
                text: 'Zmienił się koszt wybranej przez Ciebie usługi medycznej.',
            },
        },
    },
}
