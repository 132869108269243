import { EVENTS_COMFINO } from '~/utils/consts'

export default function ({ store, $analytics }, inject) {
    store.commit('setPopup', {
        vislble: false,
        type: 'error',
        title: '',
        text: '',
    })
    const show = (type, message, sentryId) => {
        const title = message?.title || 'Coś poszło nie tak'
        const text = message?.text || 'Nie przejmuj się. Jeśli problem będzie się powtarzał zawsze możesz dokończyć wypełnianie formularza klikając w link, który wysłaliśmy Ci na e-maila.'
        store.commit('setPopup', {
            visible: true,
            type,
            title,
            text: text + (sentryId ? `\nID: ${sentryId}` : ''),
        })
        $analytics(EVENTS_COMFINO.POPUP_ERROR, { value: `${title}. ${text}` })
    }
    const popup = {
        error: (message, sentryId) => {
            show('error', message, sentryId)
        },
    }
    inject('popup', popup)
}
