<template>
    <v-app class="layout-1">
        <v-toolbar
            text
            class="elevation-0 flex-grow-0 mb-1"
        >
            <v-toolbar-title class="toolbar__logo-wrapper">
                <img
                    src="/logo.svg"
                    class="toolbar__logo"
                    alt="logo ComperiaRaty"
                    title="logo ComperiaRaty"
                />
            </v-toolbar-title>
        </v-toolbar>
        <v-main>
            <v-container
                fluid
                fill-height
                class="pa-0 pb-6 pa-md-6"
                style="align-items: start"
            >
                <nuxt />
            </v-container>
        </v-main>
        <Footer />
        <Popup />
        <CookieInfo />
    </v-app>
</template>

<script>
import themeColors from '~/assets/versions/1/colors-palette.scss'
import Footer from '~/components/partials/footer/1/Footer'
import Popup from '~/components/popups/Popup'
import CookieInfo from '~/components/partials/cookie-info/1/CookieInfo'

export default {
    components: {
        Footer,
        Popup,
        CookieInfo,
    },
    data () {
        return {
            themeColors,
        }
    },
    head () {
        return {
            title: 'Wniosek COMPERIARATY',
        }
    },
}
</script>
