<template>
    <Dialog v-model="showTermsOfService">
        <div v-dompurify-html="termsOfService"></div>
    </Dialog>
</template>

<script>
import Dialog from '~/components/partials/dialog/1/Dialog'
import termsOfService from '../terms-of-service'

export default {
    components: {
        Dialog,
    },
    mixins: [termsOfService],
}
</script>

<style lang="scss">
@import '~/assets/versions/1/colors-palette.scss';

.terms {
    &__title {
        margin-bottom: 8px;
        color: $default-font-color;
        margin-left: 0px;
        font-weight: 500;
    }
    &__paragraph {
        text-align: center;
        margin-bottom: 0px;
        margin-top: 32px;
    }
    &__table {
        border-collapse: collapse;
        & tr, td {
            border: 1px solid grey;
        }
        & td {
            vertical-align: top;
            padding: 7px;
        }
    }
}
</style>
