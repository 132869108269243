<template>
    <div class="badge">
        {{ number }}
    </div>
</template>

<script>
export default {
    props: {
        number: {
            type: Number,
            required: true,
        },
    },
}
</script>

<style lang="scss" scoped>
.badge {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: rgba(#006fb2, 0.1);
    color: #006fb2;
    font-size: 12px;
    font-weight: 500;
}
</style>
