<template>
    <Dialog v-model="showTermsOfService">
        <div v-dompurify-html="termsOfService"></div>
    </Dialog>
</template>

<script>
import Dialog from '~/components/partials/dialog/ecommerce/Dialog'
import termsOfService from '../terms-of-service'

export default {
    components: {
        Dialog,
    },
    mixins: [termsOfService],
}
</script>
