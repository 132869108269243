import { mapFields } from 'vuex-map-fields'
import axios from 'axios'

export default {
    computed: {
        ...mapFields([
            'blogContent',
        ]),
    },
    methods: {
        async getPosts (postType = null) {
            if (!postType) {
                return false
            }

            const response = await axios.get('/' + postType + '.json')
            this.blogContent[postType] = response.data
        },
    },
}
