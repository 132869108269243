import Vue from 'vue'
import VueDOMPurifyHTML from 'vue-dompurify-html'

const TEMPORARY_ATTRIBUTE = 'data-href-target'

Vue.use(VueDOMPurifyHTML, {
    hooks: {
        beforeSanitizeAttributes: (node) => {
            if (node.tagName === 'A' && node.hasAttribute('target')) {
                node.setAttribute(TEMPORARY_ATTRIBUTE, node.getAttribute('target'))
            }
        },
        afterSanitizeAttributes: (node) => {
            if (node.tagName === 'A' && node.hasAttribute(TEMPORARY_ATTRIBUTE)) {
                node.setAttribute('target', node.getAttribute(TEMPORARY_ATTRIBUTE))
                node.removeAttribute(TEMPORARY_ATTRIBUTE)
                if (node.getAttribute('target') === '_blank') {
                    node.setAttribute('rel', 'noopener')
                }
            }
        },
    },
})
