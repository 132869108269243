import getComfinoBaseParams from './utils/get-comfino-base-params'
import getComperiaratyBaseParams from './utils/get-comperiaraty-base-params'
import { hasRouteUTM, prepareRouteWithUTM } from './utils/utm'

const GTM_ID_COMPERIARATY = process.env.GTM_ID_COMPERIARATY
const GTM_ID_COMFINO = process.env.GTM_ID_COMFINO

export default function (context, inject) {
    const { app, route, store, $gtm } = context
    const isComfino = route.path.split('/')[1] === 'ecommerce'

    $gtm.init(isComfino ? GTM_ID_COMFINO : GTM_ID_COMPERIARATY)

    inject('analytics', (event = '', params = {}) => {
        const { backendDataLayer } = store.state.analytics
        const baseParams = isComfino ? getComfinoBaseParams(context) : getComperiaratyBaseParams(context)

        const paramsWithPrefix = {}
        for (const [key, value] of Object.entries({ ...baseParams, ...params })) {
            paramsWithPrefix[`param_${key}`] = value
        }

        $gtm.push({
            event,
            ...backendDataLayer,
            ...paramsWithPrefix,
        })
    })

    // keep UTM
    // @TODO: Try to store it in store/analytics
    app.router.beforeEach((to, from, next) => {
        if (!hasRouteUTM(from) || hasRouteUTM(to)) {
            next()
        } else {
            next(prepareRouteWithUTM(to, from))
        }
    })
}
