import { mapFields } from 'vuex-map-fields'
import { EVENTS } from '~/utils/consts'

export default {
    computed: {
        ...mapFields([
            'privacyPolicy',
            'showPrivacyPolicy',
        ]),
    },
    watch: {
        showPrivacyPolicy (value) {
            if (value) {
                this.$analytics(EVENTS.PRIVACY_POLICY_OPEN)
            } else {
                this.$analytics(EVENTS.PRIVACY_POLICY_CLOSE)
            }
        },
    },
}
