import configurationFromBackend from '~/plugins/configuration-from-backend'
import updateContent from '~/plugins/update-content'

export default async function updateConfiguration ($axios, store) {
    if (store.state.configuration.isLoaded === true) {
        return
    }
    await configurationFromBackend($axios, store, 'ECOMMERCE')
    await updateContent($axios, store, 'ECOMMERCE')

    store.commit('configuration/setLoaded')
}
