<template>
    <v-app
        class="layout-1"
        :class="{'pb-60': $vuetify.breakpoint.mdAndDown}"
    >
        <AppToolbar />

        <v-main
            :class="{'whitelabel-background': backgroundUrl != null}"
            :style="{'background-image': backgroundUrl && $vuetify.breakpoint.lgAndUp ? `url(${backgroundUrl})` : 'none'}"
        >
            <v-container
                :class="{'pt-50': $vuetify.breakpoint.mdAndUp}"
                px-0
                fluid
            >
                <nuxt />
            </v-container>
        </v-main>
        <div
            class="toolbar-mobile hidden-lg-and-up"
            :class="{'toolbar-mobile--hide': isFieldFocus}"
        >
            <TabsMenu :mobile="true" />
        </div>
        <AppFooter />
        <AppPopup />
        <AppCookieInfo />
    </v-app>
</template>

<script>
import { mapFields } from 'vuex-map-fields'

import Toolbar from '~/components/partials/toolbar/1/Toolbar'
import Footer from '~/components/partials/footer/1/Footer'
import Popup from '~/components/popups/Popup'
import TabsMenu from '~/components/partials/toolbar/1/TabsMenu'
import CookieInfo from '~/components/partials/cookie-info/1/CookieInfo'

export default {
    components: {
        AppToolbar: Toolbar,
        AppFooter: Footer,
        AppPopup: Popup,
        TabsMenu: TabsMenu,
        AppCookieInfo: CookieInfo,
    },
    head () {
        return {
            title: 'Wniosek COMPERIARATY',
        }
    },
    computed: {
        ...mapFields([
            'isFieldFocus',
            'whitelabel.source.backgroundUrl',
        ]),
    },
}
</script>

<style lang="scss" scoped>
.whitelabel-background {
    background-size: cover;
    background-attachment: fixed;
}
</style>
