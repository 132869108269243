import Vue from 'vue'
import getStepFromPath from '~/utils/get-step-from-path'
import copy from '~/utils/copy'

Vue.mixin({
    computed: {
        $copy () {
            const layout = this.$route.path.split('/')[1]
            const step = getStepFromPath(this.$route.path)
            if (!step || !layout) {
                return ''
            }
            const { store } = this.$store.state.ecommerce
            const partner = store ? store.name : 'default' || 'default'
            if (!Object.keys(copy[layout]).includes(partner)) {
                return copy[layout].default[step]
            }
            return copy[layout][partner][step]
        },
    },
})
