<template>
    <Dialog v-model="showFAQ">
        <h2>
            Najczęściej zadawane pytania:
        </h2>
        <div v-if="blogContent['faq-comfino']">
            <div
                v-for="({title, content}, index) in blogContent['faq-comfino']"
                :key="index"
            >
                <h2 v-dompurify-html="title.rendered"></h2>
                <div v-dompurify-html="content.rendered"></div>
            </div>
        </div>
    </Dialog>
</template>

<script>
import Dialog from '~/components/partials/dialog/ecommerce/Dialog'
import faq from '../faq'

export default {
    components: {
        Dialog,
    },
    mixins: [faq],
    async created () {
        if (!this.blogContent['faq-comfino']) {
            await this.getPosts('faq-comfino')
        }
    },
}
</script>
