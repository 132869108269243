import dayjs from 'dayjs'

export default function getComperiaratyBaseParams ({ app, store }) {
    const { route } = app.context
    const { chosenOffer } = store.state.analytics.comperiaraty
    return {
        token: route.query.token || null,
        timestamp: dayjs().format('YYYY-MM-DD HH:mm:ss.SSS'),
        page_path: route.path,
        process_type: store.state.processType,
        sceuidjs: store.state.trackerId,
        utm_source: route.query.utm_source || null,
        utm_medium: route.query.utm_medium || null,
        utm_campaign: route.query.utm_campaign || null,
        utm_term: route.query.utm_term || null,
        utm_content: route.query.utm_content || null,
        partner_name: null,
        partner_source_name: null,
        rescue: store.state.analytics.rescue,
        chosen_creditor_name: chosenOffer.creditorName,
        chosen_installment_value_from: (chosenOffer.installmentAmount / 100).toFixed(2),
        chosen_amount: (chosenOffer.loanAmount / 100).toFixed(2),
        chosen_installment_numbers: chosenOffer.loanTerm,
    }
}
