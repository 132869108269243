<template>
    <v-toolbar
        color="white"
        :text="$vuetify.breakpoint.smAndDown"
        :height="$vuetify.breakpoint.mdAndDown ? '62px' : '85px'"
        :class="{
            'toolbar': true,
            'elevation-1': $vuetify.breakpoint.mdAndUp,
            'toolbar--on-top': visibleToolbar
        }"
        class="flex-grow-0"
    >
        <v-toolbar-title class="toolbar__logo-wrapper mr-6">
            <component
                :is="logo.displayMethod"
                :image-url="logo.imageUrl"
            />
        </v-toolbar-title>
        <!-- mobile and tablets -->
        <v-toolbar-items class="toolbar__items toolbar__items--mobile hidden-lg-and-up">
            <ToolbarOrderSummary />

            <ToolbarOfferDetails
                v-if="!$route.path.includes('offers') && !$route.path.includes('gratulations')"
                @dialog="visibleToolbar = !visibleToolbar"
            />

            <ToolbarOrderSliders
                v-if="$route.path.includes('offers')"
                class="ml-2"
                @dialog="visibleToolbar = !visibleToolbar"
            />
        </v-toolbar-items>

        <!-- desktop -->
        <div class="toolbar__items hidden-md-and-down">
            <TabsMenu />
        </div>
    </v-toolbar>
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import ToolbarOrderSummary from '~/components/partials/order-summary/ToolbarOrderSummary'
import ToolbarOfferDetails from '~/components/partials/offer/ToolbarOfferDetails'
import ToolbarOrderSliders from '~/components/partials/order-sliders/ToolbarOrderSliders'
import TabsMenu from '~/components/partials/toolbar/1/TabsMenu'
import OnlyComperiaratyDisplayMethod from '~/components/partials/toolbar/1/logo/OnlyComperiaratyDisplayMethod'
import OnlyImageDisplayMethod from '~/components/partials/toolbar/1/logo/OnlyImageDisplayMethod'
import PartnerLogoOnBottomDisplayMethod from '~/components/partials/toolbar/1/logo/PartnerLogoOnBottomDisplayMethod'

export default {
    components: {
        OnlyComperiaratyDisplayMethod,
        OnlyImageDisplayMethod,
        PartnerLogoOnBottomDisplayMethod,
        ToolbarOrderSummary,
        ToolbarOfferDetails,
        ToolbarOrderSliders,
        TabsMenu,
    },
    data () {
        return {
            visibleToolbar: false,
        }
    },
    computed: {
        ...mapFields([
            'whitelabel.source.logo',
        ]),
    },
}
</script>
