import currency from 'simple-currency-formatter'

export default {
    filters: {
        formatMoney (value, type = 'zł') {
            if (type === 'PLN') {
                return currency(value, 'PLN', 2, {
                    thousandsSeparator: ' ',
                    decimalSeparator: ',',
                    spaceBetweenAmountAndSymbol: true,
                })
            } else {
                return currency(value, 'zł', 2, {
                    thousandsSeparator: ' ',
                    decimalSeparator: ',',
                    spaceBetweenAmountAndSymbol: true,
                    symbolOnLeft: false,
                })
            }
        },
    },
}
