import * as Sentry from '@sentry/browser'

export default function ({ app, store, route, $axios }, inject) {
    const eventTracker = ({ id, name, value }) => {
        if ('sce' in window) {
            window.sce.event('Input_click', { id, name, value })
        }
    }
    inject('eventTracker', eventTracker)

    function findAndSendTrackerId (trackerCookieName = 'sceuidjs', currentTrial = 0, maxTrials = 3) {
        const match = document.cookie.match(new RegExp('(^| )' + trackerCookieName + '=([^;]+)'))
        const queryToken = route.query.token
        if (match && queryToken && !store.state.trackerId) {
            store.commit('setTrackerId', match[2])
            return $axios.patch(`/orders/${queryToken}`,
                {
                    trackerId: match[2],
                },
            ).catch(error => {
                Sentry.captureException(error)
            })
        } else if (currentTrial < maxTrials) {
            setTimeout(() => findAndSendTrackerId(trackerCookieName, currentTrial + 1, maxTrials), 1500)
        }
    }

    app.router.beforeEach((to, from, next) => {
        if (to.path !== '/') {
            findAndSendTrackerId()
        }
        next()
    })
}
