<template>
    <div
        v-if="show"
        class="cookie-info elevation-13"
    >
        <div class="content-wrapper">
            <div
                class="text-center"
                :class="{'mb-2 ': $vuetify.breakpoint.smAndDown}"
            >
                Nasza strona używa plików Cookies. Dowiedz się więcej o celu ich używania - zapoznaj się z
                <span
                    class="cookie-info__link"
                    @click="showPrivacyPolicy = true"
                >
                    Polityką prywatności.
                </span>
            </div>
            <v-btn
                color="success"
                class="ml-4"
                @click="accept"
            >
                Akceptuję
            </v-btn>
        </div>
    </div>
</template>
<script>
import { mapFields } from 'vuex-map-fields'
import { EVENTS } from '~/utils/consts'

export default {
    data () {
        return {
            show: false,
        }
    },
    computed: {
        ...mapFields([
            'showPrivacyPolicy',
        ]),
    },
    mounted () {
        if (localStorage && localStorage.getItem('cookieAccepted')) {
            this.show = false
            return
        }
        this.show = true
    },
    methods: {
        accept () {
            this.$analytics(EVENTS.COOKIE_INFO_CLICK)
            this.show = false
            if (localStorage) {
                localStorage.setItem('cookieAccepted', new Date().getTime())
            }
        },
    },
}
</script>

<style lang="scss" scoped>
@import '~/assets/versions/1/colors-palette.scss';

.cookie-info {
    position: sticky;
    left: 0;
    bottom: 0;
    display: flex;
    align-items: flex-end;
    width: 100%;
    z-index: 10000;
    transition: opacity .4s ease-in 0s;
    pointer-events: none;

    &__link {
        cursor: pointer;
        color: $success;
    }

    .content-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 12px 15px;
        min-height: 60px;
        background: #fff;
        font-size: 13px;
        pointer-events: all;
    }
}

@include media-breakpoint-up(md, $vuetify-grid-breakpoints) {
    .cookie-info {
        flex-direction: row;
    }
}
</style>
