<template>
    <div class="toolbar-order-sliders">
        <v-dialog
            v-model="showSliders"
        >
            <template #activator="{ on }">
                <v-icon
                    class="toolbar-order-sliders__icon"
                    large
                    v-on="on"
                >
                    {{ showSliders ? 'close' : 'list' }}
                </v-icon>
            </template>

            <v-flex class="toolbar-order-sliders__content">
                <OrderSliders
                    controls
                />
            </v-flex>
        </v-dialog>
    </div>
</template>

<script>
import OrderSliders from '~/components/partials/order-sliders/OrderSliders'

export default {
    components: {
        OrderSliders,
    },
    data () {
        return {
            showSliders: false,
        }
    },
    watch: {
        showSliders (newValue) {
            newValue
                ? this.$emit('dialog', newValue)
                : setTimeout(() => this.$emit('dialog'), 350)
        },
    },
}
</script>

<style lang="scss" scoped>
@import "~/assets/versions/1/colors-palette.scss";

.toolbar-order-sliders {
    &__content {
        background: #fff;
        padding: 30px 15px;
        height: 100%;
    }
    &__icon {
        color: $default-font-color !important;
    }
}
</style>
