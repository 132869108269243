export const state = () => ({
    rescue: false,
    backendDataLayer: null,
    comperiaraty: {
        chosenOffer: {
            creditorName: null,
            installmentAmount: null,
            loanAmount: null,
            loanTerm: null,
        },
    },
    comfino: {
        chosenProduct: null,
        chosenOffer: {
            creditorName: null,
            loanTerm: null,
            installmentAmount: null,
            rrso: null,
        },
    },
})

export const mutations = {
    setRescue: (state, payload) => {
        state.rescue = payload
    },
    setBackendDataLayer: (state, payload) => {
        state.backendDataLayer = payload
    },
    setComperiaratyChosenOffer: (state, { creditorName, loanTerm, loanAmount, installmentAmount }) => {
        state.comperiaraty.chosenOffer = {
            creditorName,
            installmentAmount,
            loanAmount,
            loanTerm,
        }
    },
    setComfinoChosenProduct: (state, payload) => {
        state.comfino.chosenProduct = payload
    },
    setComfinoChosenOffer: (state, { creditorName, loanTerm, installmentAmount, rrso }) => {
        state.comfino.chosenOffer = {
            creditorName,
            loanTerm,
            installmentAmount,
            rrso,
        }
    },
}
