<template>
    <v-layout
        column
        :class="{'controls-always' : controlsAlways}"
        class="slider"
    >
        <div class="slider__header">
            <span class="slider__label">{{ label }}</span>

            <v-money
                :id="id"
                :value="internalValue"
                v-bind="config"
                :min="min"
                :max="max"
                class="slider__input"
                @blur.native="handleBlur"
                @keydown.native.enter="$event.target.blur()"
            ></v-money>
        </div>

        <v-layout
            align-center
        >
            <button
                v-if="controls"
                :class="[controlsAlways ? 'mr-2' : 'mr-6']"
                class="slider__control"
                @click="subtractStep"
            >
                <span>-</span>
            </button>

            <v-slider
                :value="internalValue"
                :track-color="trackColor"
                :min="min"
                :max="max"
                :step="step"
                hide-details
                thumb-label
                thumb-size="48"
                @input="handleChange"
            ></v-slider>

            <button
                v-if="controls"
                :class="[controlsAlways ? 'ml-2' : 'ml-6']"
                class="slider__control"
                @click="addStep"
            >
                <span>+</span>
            </button>
        </v-layout>

        <v-flex slider__footer>
            <span>min {{ min }} {{ unit }}</span>
            <span>max {{ max }} {{ unit }}</span>
        </v-flex>
    </v-layout>
</template>

<script>
import { Money } from 'v-money'

export default {
    components: {
        'v-money': Money,
    },
    props: {
        trackColor: {
            type: String,
            default: '#aac6f7',
        },
        label: {
            type: String,
            required: true,
        },
        min: {
            type: Number,
            required: true,
        },
        max: {
            type: Number,
            required: true,
        },
        step: {
            type: Number,
            default: 1,
        },
        unit: {
            type: [String, Boolean],
            default: false,
        },
        value: {
            type: Number,
            required: true,
        },
        controls: {
            type: Boolean,
            default: false,
        },
        controlsAlways: {
            type: Boolean,
            default: false,
        },
        id: {
            type: String,
            default: null,
        },
    },
    data () {
        return {
            internalValue: this.value,
            config: {
                decimal: '.',
                thousands: ' ',
                prefix: '',
                suffix: this.unit === 'zł' ? ' zł' : '',
                precision: 0,
                masked: false,
            },
        }
    },
    watch: {
        value (newValue) {
            this.internalValue = newValue
        },
    },
    methods: {
        handleChange (value) {
            this.$emit('input', value)
        },
        handleBlur (event) {
            const targetInputReferences = event.target
            let newInternalValue = targetInputReferences.value
                .replace('zł', '')
                .replace(/\s/g, '')
            if (newInternalValue < this.min) {
                targetInputReferences.value = `${this.min} ${this.config.suffix}`
                newInternalValue = this.min
            }
            if (newInternalValue > this.max) {
                targetInputReferences.value = `${this.max} ${this.config.suffix}`
                newInternalValue = this.max
            }
            this.$emit('input', Number(newInternalValue))
        },
        addStep () {
            const currentValue = Number(this.internalValue)
            if (currentValue < this.max) {
                this.$emit('input', currentValue + this.step)
            }
        },
        subtractStep () {
            const currentValue = Number(this.internalValue)
            if (currentValue > this.min) {
                this.$emit('input', currentValue - this.step)
            }
        },
    },
}
</script>

<style lang="scss">
@import '~/assets/versions/1/colors-palette.scss';
.slider {
    .v-input__slider {
        margin-top: 0;
    }
    &__header {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        margin-bottom: 12px;
    }
    &__input {
        max-width: 85px;
        height: 40px;
        padding-right: 10px;
        padding-left: 10px;
        text-align: right;
        border: 1px solid $default-font-color;
        border-radius: 4px;
        color: $dark-font-color;
        background: #fff;
        font-weight: 500;
    }
    &__label {
        color: $default-font-color;
        font-size: 16px;
    }
    &__control {
        position: relative;
        width: 30px;
        min-width: 30px;
        height: 30px;
        background-color: $slider-control-color;
        border-radius: 50%;
        color: #fff;
        font-size: 26px;
        & > span {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            height: 30px;
            line-height: 30px;
        }
    }
    &__footer {
        display: flex;
        justify-content: space-between;
        margin-top: 8px;
        font-size: 10px;
        color: $light-font-color;
    }
    .v-slider--horizontal {
        margin: 0;
        .v-slider__track-container {
            height: 4px;
            border-radius: 4px;
        }
    }
    .theme--light.v-slider .v-slider__track-fill {
        background: $main-gradient;
    }
    .v-slider__thumb {
        width: 24px;
        height: 24px;
        left: -12px;
        &::before {
            top: -6px;
            left: -6px;
        }
        &, &.primary {
            background-color: $accent !important;
        }
    }
    & + & {
        margin-top: 25px;
    }
}
@include media-breakpoint-up(lg, $vuetify-grid-breakpoints) {
    .slider:not(.controls-always) {
        .slider__header {
            margin-bottom: 0px;
        }
        .slider__control {
            display: none;
        }
        .slider__footer {
            margin-top: 4px;
        }
    }
}

</style>
