export default async function updateCustomerData ($axios, store, queryToken) {
    const response = await $axios.get(`/orders/${queryToken}/customer`)
    const customerData = response.data

    for (const field in customerData) {
        const value = customerData[field]
        if (
            Object.prototype.hasOwnProperty.call(store.state.application, field) &&
            value !== null
        ) {
            store.commit('setApplicationField', {
                key: field,
                value,
            })
        }
    }
}
