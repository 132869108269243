<template>
    <div style="height: inherit">
        <div
            class="tabs__item"
            :class="{ 'tabs__item--active': active }"
            :style="{ 'z-index': zIndex }"
        >
            <div
                v-if="first && !mobile"
                class="triangle triangle--left disabled shadow"
            ></div>
            <div
                v-if="!last || active"
                class="triangle triangle--right"
                :class="{
                    'enabled': active,
                    'disabled': !active,
                    'shadow': !active
                }"
            ></div>
            <div class="tabs--step-information">
                <v-icon
                    class="pl-12 flex-grow-1"
                    :color="completed ? 'success' : active ? 'white' : '#4a4a4a'"
                >
                    {{ completed ? 'check_circle' : icon }}
                </v-icon>
                <span
                    class="flex-grow-1 subheading pl-2"
                    :class="{ 'success--text': completed }"
                >
                    {{ label }}
                </span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        label: {
            type: String,
            required: true,
        },
        icon: {
            type: String,
            required: true,
        },
        completed: {
            type: Boolean,
            required: true,
        },
        active: {
            type: Boolean,
            required: true,
        },
        first: {
            type: Boolean,
            required: true,
        },
        last: {
            type: Boolean,
            required: true,
        },
        mobile: {
            type: Boolean,
            required: true,
        },
        zIndex: {
            type: String,
            required: false,
            default: 'auto',
        },
    },
}
</script>

<style lang="scss" scoped>
@import '~/assets/versions/1/colors-palette.scss';
.tabs__item {
    height: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background-image: none;
    color: $default-font-color;
    text-transform: none;
    @include rem-fallback(font-size, 13);
    &--active {
        background-image: $main-gradient;
        color: white !important;
        opacity: 1;
        z-index: auto;
    }
    &--finished {
        color: $success !important;
        opacity: 1;
        background: red;
    }
    &--info {
        color: $default-font-color !important;
        opacity: 1;
        position: absolute;
        bottom: -20px;
    }
}
.tabs--step-information {
    display: flex;
    flex: 1 1 auto;
    max-width: 100%;
}
.triangle {
    position: absolute;
    width:  85px;
    height: 85px;
    text-align: left;
    transform: rotate(45deg);
    border-top-right-radius: 30%;
    &.triangle--left {
        left: -60px;
        z-index: 1;
    }
    &.triangle--right {
        right: -26px;
        z-index: -1;
    }
    &.shadow {
        box-shadow: 8px -6px 30px -14px grey
    }
    &.enabled {
        background-color: #262672;
    }
    &.disabled {
        background-color: white;
    }
    &::before, &::after {
        content: '';
        position: absolute;
        background-color: inherit;
        width:  85px;
        height: 85px;
    }
    &::before {
        transform: rotate(-135deg) skewX(-45deg) scale(1.414,.707) translate(0,-50%);
    }
    &::after {
        transform: rotate(135deg) skewY(-45deg) scale(.707,1.414) translate(50%);
    }
}
@include media-breakpoint-down(md, $vuetify-grid-breakpoints) {
    .tabs__item {
        flex: 0 1 auto !important;
        width: calc(20vw + 15px) !important;
        .triangle--right {
            right: -11px !important;
        }
        margin-left: -15px !important;
        &--active {
            z-index: auto;
        }

    }
    .tabs--step-information {
        flex-direction: column;
        align-items: center;
        margin-top: 4px;
        span, i {
            font-size: 12px !important;
            &.subheading {
                padding-left: 45px !important;
            }
        }
    }
}
@include media-breakpoint-up(md, $vuetify-grid-breakpoints) {
    .tabs__item {
        width: 200px;
    }
}
</style>
