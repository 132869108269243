import { getStatusCode, handlerErrorEcommerce } from '~/plugins/error-handler'
import updateOrderData from '~/plugins/update-order-data'
import updateCustomerAccount from '~/plugins/update-customer-account'
import updateEcommerceData from '~/plugins/update-ecommerce-data'
import getStepFromPath from '~/utils/get-step-from-path'
import Cookies from 'js-cookie'
import { COMFINO_SID_KEY, ORDER_TOKEN_KEY } from '~/utils/consts'
import updateCustomerData from '~/plugins/update-customer-data'

async function updateEcommerceStoreData ($axios, store, token) {
    const storeResponse = await $axios.get(`${process.env.API_ECOMMERCE_ORIGIN}/store/${token}`)

    store.commit('ecommerce/setStore', storeResponse.data)
}

async function updateData ($axios, $accountAxios, store, token, $popup) {
    if (store.state.firstLoad) {
        store.commit('setFirstLoad', false)
        await Promise.all([
            updateCustomerData($axios, store, token),
            updateEcommerceData($axios, store, token),
        ])
        await updateCustomerAccount($accountAxios, store, $popup)
    }
}

export async function getSession ($accountAxios, token) {
    let comfinoSid = Cookies.get(COMFINO_SID_KEY)
    if (!comfinoSid || Cookies.get(ORDER_TOKEN_KEY) !== token) {
        comfinoSid = await initialiseSession($accountAxios, token)
    }
    return comfinoSid
}
async function initialiseSession ($accountAxios, token) {
    const response = await $accountAxios.post(`init-session/${token}`)
    const comfinoSid = response.data[COMFINO_SID_KEY]
    saveSession(comfinoSid, token)

    return comfinoSid
}
function saveSession (comfinoSid, token) {
    const URLObject = new URL(window.location)
    const cookieDomain = URLObject.host.replace('wniosek', '')
    Cookies.set(COMFINO_SID_KEY, comfinoSid, { domain: cookieDomain, sameSite: 'strict', expires: 1 })
    Cookies.set(ORDER_TOKEN_KEY, token, { expires: 1 })
}

export default async function ({ app, route, store, $axios, $accountAxios, $popup, redirect, $sentry }) {
    const currentLayout = route.path.split('/')[1]
    if (currentLayout !== 'ecommerce') {
        return
    }

    const currentStep = getStepFromPath(route.path)

    const { token } = route.query
    if (!token) {
        throw Error('No query token')
    }

    if (currentStep === 'logowanie') {
        const comfinoSid = Cookies.get(COMFINO_SID_KEY)
        if (!comfinoSid || Cookies.get(ORDER_TOKEN_KEY) !== token) {
            return redirect(`/ecommerce/verify/?token=${token}`)
        }
    }

    await updateEcommerceStoreData($axios, store, token)
    await updateOrderData($axios, store, token)
    await getSession($accountAxios, token)

    try {
        const stepResponse = await $axios.get(`/orders/${token}/step`)
        const flowStep = stepResponse.data

        store.commit('setFlowStep', flowStep)
        if (store.state.flowStep.allowedSteps.includes(currentStep)) {
            if (currentStep === 'verify' || currentStep === 'logowanie') {
                store.commit('setFirstLoad', true)
                return
            }
            await updateData($axios, $accountAxios, store, token, $popup)
        } else {
            return redirect(`/${flowStep.layout}/${flowStep.step}/?token=${token}`)
        }
    } catch (error) {
        if (getStatusCode(error) === 401) {
            return redirect(`/ecommerce/verify/?token=${token}`)
        }
        await handlerErrorEcommerce($sentry, app.router, $axios, route, $popup, error, {
            title: 'Nie udało się pobrać danych Twojego wniosku.',
            text: 'Upewnij się, że otworzony został właściwy link. Nie przejmuj się, jeśli problem będzie się powtarzał - nasz konsultant skontaktuje się z Tobą i pomoże Ci dokończyć wniosek.',
        })
    }
}
