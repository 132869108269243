<template>
    <Dialog v-model="showFAQ">
        <h2 class="mb-4 pa-0">
            Najczęściej zadawane pytania:
        </h2>
        <div v-if="blogContent.faq">
            <div
                v-for="({title, content}, index) in blogContent.faq"
                :key="index"
            >
                <h2 class="mb-2 mt-4 pa-0">
                    {{ title.rendered }}
                </h2>
                <div v-dompurify-html="content.rendered"></div>
            </div>
        </div>
    </Dialog>
</template>

<script>
import Dialog from '~/components/partials/dialog/1/Dialog'
import faq from '../faq'

export default {
    components: {
        Dialog,
    },
    mixins: [faq],
    async created () {
        if (!this.blogContent.faq) {
            await this.getPosts('faq')
        }
    },
}
</script>
