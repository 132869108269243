<template>
    <header class="toolbar-mobile">
        <div class="toolbar-mobile__placeholder"></div>
        <div class="toolbar">
            <div class="toolbar__content">
                <div class="logo">
                    <img
                        src="/logo-comfino.svg"
                        alt="logo Comfino"
                    />
                </div>
                <div
                    v-if="store && store.logoUrl"
                    class="shop-logo"
                >
                    Partner biznesowy
                    <img
                        :src="store.logoUrl"
                        :alt="store.name"
                    />
                </div>
                <div
                    v-if="showCart"
                    class="cart"
                    @click="$refs.cart.open = !cartOpen"
                >
                    <img
                        v-show="!cartOpen"
                        src="/icons/cart.svg"
                        alt="ikona koszyka"
                    />
                    <CartBadge
                        v-show="!cartOpen"
                        :number="products.length"
                        class="badge"
                    />
                    <img
                        v-show="cartOpen"
                        src="/icons/close.svg"
                        alt="ikona zamknięcia"
                    />
                </div>
            </div>
            <div
                v-if="tabsVisible"
                class="toolbar__progress"
            >
                <span
                    v-for="(tab, index) in tabs"
                    :key="index"
                    class="toolbar__progress__item"
                    :class="{
                        active: index === activeTabIndex,
                        done: index < activeTabIndex
                    }"
                ></span>
            </div>
        </div>
        <CartMobile
            v-if="showCart"
            ref="cart"
            @change="cartOpen = $event"
        />
    </header>
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import toolbar from './toolbar'
import CartBadge from '~/components/partials/cart/ecommerce/CartBadge'
import CartMobile from '~/components/partials/cart/ecommerce/CartMobile'
import getStepFromPath from '~/utils/get-step-from-path'

export default {
    components: {
        CartBadge,
        CartMobile,
    },
    mixins: [toolbar],
    data () {
        return {
            cartOpen: false,
        }
    },
    computed: {
        ...mapFields(
            'ecommerce', [
                'store',
                'cart.products',
            ],
        ),
        showCart () {
            return getStepFromPath(this.$route.path) !== 'verify'
        },
    },
}
</script>

<style lang="scss" scoped>
$toolbar-height: 68px;
$progress-height: 4px;
.toolbar-mobile {
    position: relative;
    z-index: 2;
    &__placeholder {
        height: $toolbar-height;
    }
}
.toolbar {
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    height: $toolbar-height;
    &__content {
        position: relative;
        display: flex;
        align-items: center;
        height: #{$toolbar-height - $progress-height};
        padding: 0 16px;
        background: #fff;
        .logo {
            height: 50px;
            img {
                height: 100%;
            }
        }
        .shop-logo {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-left: 16px;
            font-size: 10px;
            color: #aaa;
            img {
                height: 20px;
            }
        }
        .cart {
            position: absolute;
            top: 50%;
            right: 30px;
            transform: translateY(-50%);
            height: 20px;
            cursor: pointer;
            img {
                height: 100%;
            }
            .badge {
                position: absolute;
                top: -14px;
                right: -15px;
            }
        }
    }
    &__progress {
        display: flex;
        height: $progress-height;
        &__item {
            display: block;
            flex-grow: 1;
            background: #e4e4e4;
            transition: 0.3s ease;
            &.active {
                background: #03af36;
            }
            &.done {
                background: #4bbf4e;
            }
        }
    }
}

@media (min-width: 360px) {
    .toolbar__content {
        .logo {
            height: 50px;
        }
        .shop-logo {
            img {
                height: 25px;
            }
        }
    }
}
@media (min-width: 400px) {
    .toolbar__content {
        .shop-logo {
            margin-left: 32px;
        }
    }
}
</style>
