<template>
    <div class="tabs">
        <Tab
            v-for="(tab, index) in tabs"
            :key="index"
            :label="tab.label"
            :icon="tab.icon"
            :completed="index < activeTabIndex"
            :active="index === activeTabIndex"
            :first="index === 0"
            :last="index === tabs.length - 1"
            :z-index="getTabZIndex(index)"
            :mobile="mobile"
        />
    </div>
</template>

<script>
import Tab from '~/components/partials/toolbar/1/Tab'

export default {
    components: {
        Tab,
    },
    props: {
        mobile: {
            type: Boolean,
            default: false,
        },
    },
    data () {
        return {
            tabs: [
                {
                    label: 'Dane',
                    icon: 'account_circle',
                    steps: [
                        'step-1',
                        'step-2',
                        'step-2-1',
                        'step-3',
                        'step-4',
                        'step-5',
                    ],
                },
                {
                    label: 'Propozycja',
                    icon: 'assignment',
                    steps: [
                        'offers',
                        'fallback',
                        'additional-fields',
                        'long-request-redirect',
                        'affiliation-redirect',
                        'will-be-contacted',
                        'chat',
                    ],
                },
                {
                    label: 'Weryfikacja',
                    icon: 'verified_user',
                    steps: [
                        'waiting',
                        'income-verification',
                        'income-verification-status',
                        'identity-verification',
                        'identity-verification-status',
                    ],
                },
                {
                    label: 'Oferta',
                    icon: 'assignment_turned_in',
                    steps: [
                        'offer-proposition',
                        'accepted',
                        'sign-agreement',
                    ],
                },
                {
                    label: 'Wypłata',
                    icon: 'account_balance_wallet',
                    steps: ['gratulations'],
                },
            ],
        }
    },
    computed: {
        activeTabIndex () {
            let activeIndex = 0
            this.tabs.forEach((tab, index) => {
                tab.steps.forEach(step => {
                    if (this.$route.path.includes(step)) {
                        activeIndex = index
                    }
                })
            })
            return activeIndex
        },
    },
    methods: {
        getTabZIndex (index) {
            if (index > this.activeTabIndex) {
                return `${this.activeTabIndex - index - 1}`
            }
            if (index < this.activeTabIndex) {
                return `${this.activeTabIndex - index}`
            }
            return 'auto'
        },
    },
}
</script>

<style lang="scss" scoped>
@import '~/assets/versions/1/colors-palette.scss';

.tabs {
    overflow: hidden;
    display: flex;
    margin-left: -28px;
    margin-right: -15px;
    height: 56px;
    &:last-child {
        padding-right: 50px;
    }
    &--step-information {
        span, i {
            z-index: 2;
        }
    }
}
@include media-breakpoint-up(lg, $vuetify-grid-breakpoints) {
    .tabs {
        height: 85px;
        margin-left: initial;
        margin-right: initial;
    }
}
</style>
