function clearHistory () {
    window.history.pushState(null, '', window.location.href)
}

export default function blockBackButton ({ app }) {
    clearHistory()
    window.onpopstate = function () {
        clearHistory()
        history.go(1)
    }

    app.router.afterEach(() => {
        clearHistory()
    })
}
