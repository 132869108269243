<template>
    <v-app class="telepolis">
        <div class="height-wrapper">
            <header class="header">
                <h1 class="desktop-only">
                    Zgarnij kasę na telefon
                </h1>
                <img
                    src="/telepolis/logo.png"
                    alt="Logo"
                    class="logo mobile-only"
                />
            </header>
            <nuxt />
            <AppPopup />
        </div>
    </v-app>
</template>
<script>
import Popup from '~/components/popups/Popup'

export default {
    components: {
        AppPopup: Popup,
    },
    head () {
        return {
            title: 'Wniosek COMPERIARATY',
        }
    },
}
</script>
<style lang="scss">
@import "~/assets/widget/styles.scss";
.telepolis {
    .header {
        color: #BD171F;
    }
    .footer {
        img:first-child {
            height: 30px;
        }
    }
    @media (max-width: 599px) {
        .header {
            background-color: black;
            img {
                height: 30px;
            }
        }
        .content {
            h1 {
                color: #BD171F;
            }
            h3 {
                font-size: 18px;
            }
        }
    }
}
</style>
