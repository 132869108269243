import { getField } from 'vuex-map-fields'

export const state = () => ({
    isLoaded: false,
    creditors: {},
    limitValues: {
        loanAmount: {
            min: 100000,
            max: 5000000,
            default: 500000,
            step: 10000,
        },
        loanTerm: {
            min: 3,
            max: 60,
            default: 24,
            step: 1,
        },
    },
    content: {
        privacyPolicy: 'Wczytuję…',
        termsOfService: 'Wczytuję…',
    },
})

export const mutations = {
    setConfig: (state, payload) => {
        state.creditors = payload.creditorsContent
        state.limitValues = payload.limitValues
    },
    setContent: (state, payload) => {
        console.log(payload)
        state.content = payload
    },
    setLoaded: (state) => {
        state.isLoaded = true
    },
}

export const getters = {
    getField,
}
