export default async function ({ store, route, redirect }) {
    const bc = new BroadcastChannel('wniosek')
    const { token } = route.query

    bc.onmessage = ({ data }) => {
        if (!data.includes(token)) return
        store.commit('setIsWindowActiveStatus', false)
        // since the flag above blocks all axios requests/responses,
        // the redirect below will only trigger (show) nuxt loader page, thus no real redirect will occur
        redirect(`/blank?token=${token}`)
    }

    function callback () {
        bc.postMessage(`Deactivate other tabs. Source token: ${token}`)
        if (store.state.isWindowActive === false) {
            store.commit('setIsWindowActiveStatus', true)
            location.reload()
        }
    }

    window.addEventListener('load', callback)
    window.addEventListener('focus', callback)
}
