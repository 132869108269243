<template>
    <div>
        <img
            src="/logo.svg"
            class="toolbar__logo"
            alt="logo ComperiaRaty"
            title="logo ComperiaRaty"
        />
        <div class="toolbar__logo-partner pt-1">
            <small>partner</small>
            <img
                :src="imageUrl"
                alt="logo Partnera"
            />
        </div>
    </div>
</template>
<script>

export default {
    props: {
        imageUrl: {
            type: String,
            required: true,
        },
    },
}
</script>
