import { mapFields } from 'vuex-map-fields'
import getBlogContent from '~/mixins/get-blog-content'
import { EVENTS } from '~/utils/consts'

export default {
    mixins: [getBlogContent],
    data () {
        return {
            contactEmail: 'reklamacje@comeriaraty.pl',
        }
    },
    computed: {
        ...mapFields([
            'showFAQ',
            'blogContent',
        ]),
        contactEmailAttr () {
            return `mailto:${this.contactEmail}`
        },
    },
    watch: {
        showFAQ (value) {
            if (value) {
                this.$analytics(EVENTS.FAQ_OPEN)
            } else {
                this.$analytics(EVENTS.FAQ_CLOSE)
            }
        },
    },
}
