import getStepFromPath from '../utils/get-step-from-path'

export default function ({ app, $axios }) {
    app.router.afterEach(async (route) => {
        const { token } = route.query
        if (!token) {
            return
        }
        const step = getStepFromPath(route.path)
        await $axios.post(`/orders/${token}/extend-time?step=${step}`)
    })
}
