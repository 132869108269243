<template>
    <v-dialog
        :value="value"
        max-width="800px"
        transition="dialog-bottom-transition"
        @input="$emit('input', false)"
    >
        <v-btn
            icon
            class="dialog-close"
            @click="$emit('input', false)"
        >
            <v-icon>
                mdi-close-circle-outline
            </v-icon>
        </v-btn>
        <div class="dialog-content">
            <slot></slot>
        </div>
    </v-dialog>
</template>

<script>
export default {
    props: {
        value: {
            type: Boolean,
            default: false,
        },
    },
}
</script>

<style lang="scss">
.ecommerce .v-dialog {
    box-shadow: none;
    position: relative;
    display: flex;
    flex-direction: column;
    margin-inline: 0;
    border-top-left-radius: 13px;
    border-top-right-radius: 13px;
    max-height: calc(100% - 80px);
    .dialog-close {
        position: absolute;
        z-index: 1;
        top: 2px;
        right: 2px;
    }
    .dialog-content {
        overflow: auto;
        max-height: 100%;
        padding: 24px 16px;
        background: #ebebeb;
    }
}

@include media-breakpoint-up(lg, $vuetify-grid-breakpoints) {
    .ecommerce .v-dialog {
        margin-top: 0;
        border-radius: 13px;
        max-height: 80%;
    }
}
</style>
