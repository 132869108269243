<template>
    <v-app class="layout-1">
        <v-toolbar
            text
            color="white"
            class="elevation-1 flex-grow-0 hidden-md-and-up"
        >
            <v-toolbar-title>
                <img
                    src="/logo.svg"
                    class="toolbar__logo"
                    alt="logo ComperiaRaty"
                    title="logo ComperiaRaty"
                />
            </v-toolbar-title>
        </v-toolbar>

        <v-main>
            <nuxt />
        </v-main>

        <AppFooter />
        <AppPopup />
        <AppCookieInfo />
    </v-app>
</template>

<script>
import Footer from '~/components/partials/footer/1/Footer'
import Popup from '~/components/popups/Popup'
import CookieInfo from '~/components/partials/cookie-info/1/CookieInfo'

export default {
    components: {
        AppFooter: Footer,
        AppPopup: Popup,
        AppCookieInfo: CookieInfo,
    },
    head () {
        return {
            title: 'Wniosek COMPERIARATY',
        }
    },
}
</script>
